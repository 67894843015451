import { useState, FormEvent } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import Notification from '../../components/notification/Notification';
import axiosClient from '../../axiosClient';


import styles from './ogolne.module.css';

interface Passwords {
    first: boolean;
    second: boolean;
    third: boolean;
    fourth: boolean;
}

interface Errors {
    first: string | null;
    second: string | null;
    third: string | null;
}

const Ogolne = () => {
    const [arePasswordsVisible, setArePasswordVisible] = useState<Passwords>({
        first: false,
        second: false,
        third: false,
        fourth: false
    });

    const [errors, setErrors] = useState<Errors>({
        first: null,
        second: null,
        third: null
    });

    const [notification, setNotification] = useState<string | null>(null);

    const togglePassword = (which: string): void => {
        setArePasswordVisible(prev => {
            let newValue: Passwords = prev;
            switch (which) {
                case 'first':
                    newValue = { ...prev, first: !prev.first };
                    break;
                case 'second':
                    newValue = { ...prev, second: !prev.second };
                    break;
                case 'third':
                    newValue = { ...prev, third: !prev.third };
                    break;
                case 'fourth':
                    newValue = { ...prev, fourth: !prev.fourth };
                    break;
            }
            return newValue;
        })
    }

    const changeLogin = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const login = form.querySelector('#loginFirst') as HTMLInputElement;
        const password = form.querySelector('#passwordFirst') as HTMLInputElement;
        const newLogin = form.querySelector('#newLoginFirst') as HTMLInputElement;

        axiosClient({
            method: 'post',
            url: '/change-login',
            data: {
                login: login?.value,
                password: password?.value,
                newLogin: newLogin?.value
            }
        })
            .then(res => {
                form.reset();
                setErrors(prev => {
                    return { ...prev, first: null }
                });
                setNotification(res.data.message);
                setTimeout(() => setNotification(null), 4000);
            })
            .catch(err => setErrors(prev => {
                return { ...prev, first: err?.response?.data.message }
            }))

    }

    const changePassword = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const login = form.querySelector('#loginSecond') as HTMLInputElement;
        const password = form.querySelector('#passwordSecond') as HTMLInputElement;
        const newPassword = form.querySelector('#newPasswordSecond') as HTMLInputElement;
        const newPasswordConfirmation = form.querySelector('#newPasswordSecondConfirmation') as HTMLInputElement;

        axiosClient({
            method: 'post',
            url: '/change-password',
            data: {
                login: login?.value,
                password: password?.value,
                newPassword: newPassword?.value,
                newPassword_confirmation: newPasswordConfirmation?.value
            }
        })
            .then(res => {
                form.reset();
                setErrors(prev => {
                    return { ...prev, second: null }
                });
                setNotification(res.data.message);
                setTimeout(() => setNotification(null), 4000);
            })
            .catch(err => setErrors(prev => {
                return { ...prev, second: err?.response?.data.message }
            }));
    }

    const changeSocialMedia = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const facebook = form.querySelector('#facebook') as HTMLInputElement;
        const twitter = form.querySelector('#twitter') as HTMLInputElement;
        const instagram = form.querySelector('#instagram') as HTMLInputElement;
        const linkedin = form.querySelector('#linkedin') as HTMLInputElement;
        const youtube = form.querySelector('#youtube') as HTMLInputElement;

        axiosClient({
            method: 'post',
            url: '/change-social-media',
            data: {
                facebook: facebook?.value || '#',
                twitter: twitter?.value || '#',
                instagram: instagram?.value || '#',
                linkedin: linkedin?.value || '#',
                youtube: youtube?.value || '#'
            }
        })
            .then(res => {
                form.reset();
                setErrors(prev => {
                    return { ...prev, third: null }
                });
                setNotification(res.data.message);
                setTimeout(() => setNotification(null), 4000);
            })
            .catch(err => setErrors(prev => {
                return { ...prev, third: err?.response?.data.message }
            }))
    }

    return (
        <main className={styles.main}>
            <Notification>{notification}</Notification>
            <section className={styles.section}>
                <h2 className={styles.section__heading}>Zmień login</h2>
                <form onSubmit={changeLogin} className={styles.section__form}>
                    <div className={styles.form__container}>
                        <label htmlFor="loginFirst" className={styles.form__label}>Login</label>
                        <input type="text" className={styles.form__input} id="loginFirst" />
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="passwordFirst" className={styles.form__label}>Hasło</label>
                        <input type={arePasswordsVisible.first ? 'text' : 'password'} className={styles.form__input} id="passwordFirst" />
                        <button onClick={() => togglePassword('first')} type='button' className={styles.form__container__eye}>
                            {arePasswordsVisible.first ? <AiFillEye /> : <AiFillEyeInvisible />}
                        </button>
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="newLoginFirst" className={styles.form__label}>Nowy login</label>
                        <input type="text" className={styles.form__input} id="newLoginFirst" />
                    </div>
                    <button className={styles.form__button}>Zapisz</button>
                </form>
                {
                    errors.first && <p role='alert' aria-live='assertive' className={styles.form__error}>{errors.first}</p>
                }
            </section>

            <section className={styles.section}>
                <h2 className={styles.section__heading}>Zmień hasło</h2>
                <form onSubmit={changePassword} className={styles.section__form}>
                    <div className={styles.form__container}>
                        <label htmlFor="loginSecond" className={styles.form__label}>Login</label>
                        <input type="text" className={styles.form__input} id="loginSecond" />
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="passwordSecond" className={styles.form__label}>Hasło</label>
                        <input type={arePasswordsVisible.second ? 'text' : 'password'} className={styles.form__input} id="passwordSecond" />
                        <button onClick={() => togglePassword('second')} type='button' className={styles.form__container__eye}>
                            {arePasswordsVisible.second ? <AiFillEye /> : <AiFillEyeInvisible />}
                        </button>
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="newPasswordSecond" className={styles.form__label}>Nowe hasło</label>
                        <input type={arePasswordsVisible.third ? 'text' : 'password'} className={styles.form__input} id="newPasswordSecond" />
                        <button onClick={() => togglePassword('third')} type='button' className={styles.form__container__eye}>
                            {arePasswordsVisible.third ? <AiFillEye /> : <AiFillEyeInvisible />}
                        </button>
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="newPasswordSecondConfirmation" className={styles.form__label}>Powtórz nowe hasło</label>
                        <input type={arePasswordsVisible.fourth ? 'text' : 'password'} className={styles.form__input} id="newPasswordSecondConfirmation" />
                        <button onClick={() => togglePassword('fourth')} type='button' className={styles.form__container__eye}>
                            {arePasswordsVisible.fourth ? <AiFillEye /> : <AiFillEyeInvisible />}
                        </button>
                    </div>
                    <button className={styles.form__button}>Zapisz</button>
                </form>
                {
                    errors.second && <p role='alert' aria-live='assertive' className={styles.form__error}>{errors.second}</p>
                }
            </section>

            <section className={styles.section}>
                <h2 className={styles.section__heading}>Social Media</h2>
                <form onSubmit={changeSocialMedia} className={styles.section__form}>
                    <div className={styles.form__container}>
                        <label htmlFor="facebook" className={styles.form__label}>Facebook(link)</label>
                        <input type="text" className={styles.form__input} id="facebook" />
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="twitter" className={styles.form__label}>Twitter(link)</label>
                        <input type="text" className={styles.form__input} id="twitter" />
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="instagram" className={styles.form__label}>Instagram(link)</label>
                        <input type="text" className={styles.form__input} id="instagram" />
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="linkedin" className={styles.form__label}>Linkedin(link)</label>
                        <input type="text" className={styles.form__input} id="linkedin" />
                    </div>
                    <div className={styles.form__container}>
                        <label htmlFor="youtube" className={styles.form__label}>YouTube(link)</label>
                        <input type="text" className={styles.form__input} id="youtube" />
                    </div>
                    <button className={styles.form__button}>Zapisz</button>
                </form>
                {
                    errors.third && <p role='alert' aria-live='assertive' className={styles.form__error}>{errors.third}</p>
                }
            </section>
        </main>
    )
}

export default Ogolne
