import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ClientTile from '../../components/clientTile/ClientTile';
import axiosClient from '../../axiosClient';
import axios from 'axios';

import styles from './klienci.module.css';

interface Client {
    id: number;
    imie: string;
    nazwisko: string;
    nazwa_firmy: string | null;
    nr_telefonu: string;
    email: string;
    created_at: string;
    updated_at: string;
}

const Klienci = () => {
    const [clients, setClients] = useState<Client[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: '/clients',
            cancelToken: source.token
        })
            .then(res => {
                setError(null);
                setClients(res.data);
            })
            .catch(err => {
                setClients(null);
                setError('Coś poszło nie tak, spróbuj ponownie później...');
            })

        return () => {
            source.cancel();
        }

    }, []);


    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <main className={styles.main}>
            <Link to='/klienci/new' className={styles.button}>Dodaj klienta</Link>
            {
                !clients ? <img className='loading' src="/loading.gif" alt="ładowanie" /> :
                    <>
                        {clients.length === 0 ? <p className={styles.noResults}>Brak klientów</p> :
                            <>
                                {
                                    clients.map(client => {
                                        return <ClientTile
                                            key={client.id}
                                            id={client.id}
                                            imie={client.imie}
                                            nazwisko={client.nazwisko}
                                            nazwa_firmy={client.nazwa_firmy}
                                            nr_telefonu={client.nr_telefonu}
                                            email={client.email}
                                        />
                                    })
                                }
                            </>
                        }
                    </>
            }

        </main>
    )
}

export default Klienci
