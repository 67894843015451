import { useState, useEffect, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import OfferTile from '../../components/offerTile/OfferTile';
import axiosClient from '../../axiosClient';
import axios from 'axios';
import { BsFillSkipStartFill, BsFillSkipEndFill } from 'react-icons/bs';
import { GrPrevious, GrNext } from 'react-icons/gr';

import styles from './oferty.module.css';

interface Offer {
    id: number;
    nazwa: string;
    miasto: string;
    dzielnica: string | null;
    numer_oferty: string;
    typ_nieruchomosci: string;
    rodzaj: string;
    liczba_pokoi: number | null;
    pietro: number | null;
    powierzchnia: number;
    cena_za_metr_kwadratowy: number;
    cena: number;
    rodzaj_budynku: string | null;
    standard: number | null;
    stan_lokalu: string | null;
    okna: string | null;
    balkon: number | null;
    opis: string;
    agent_id: number;
    client_id: number | null;
    aktywna: number;
    created_at: string;
    updated_at: string;
}
interface Links {
    url: string | null;
    label: string;
    active: boolean;
}

interface Response {
    current_page: number;
    data: Offer[] | [];
    first_page_url: string;
    from: number | null;
    last_page: number;
    last_page_url: string;
    links: Links[];
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number | null;
    total: number;
}

interface Agent {
    id: number;
    imie: string;
    nazwisko: string;
    zdjecie: string;
    zawod: string;
    nr_telefonu: string;
    email: string;
    created_at: string;
    updated_at: string;
}

const Oferty = () => {
    const [offers, setOffers] = useState<Response | null>(null);
    const [offersByAgent, setOffersByAgent] = useState<Offer[] | null>(null);
    const [agents, setAgents] = useState<Agent[] | [] | null>(null);
    const [areResultsByAgent, setAreResultsByAgent] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);
    const [searchedOffer, setSearchedOffer] = useState<Offer | null>(null);
    const [noResults, setNoResults] = useState<boolean>(false);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: `/offers-all?page=${page}`,
            cancelToken: source.token
        })
            .then(res => {
                setOffers(res.data);
                axiosClient({
                    method: 'get',
                    url: '/agents',
                    cancelToken: source.token
                })
                    .then(res => {
                        setAgents(res.data);
                        setError(null);
                    })
                    .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'))
            })
            .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'))

        return () => {
            source.cancel();
        }
    }, [page]);

    const searchOffer = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const phrase = form.querySelector('input') as HTMLInputElement;

        axiosClient({
            method: 'get',
            url: `offers-all/${phrase.value || 'null'}`
        })
            .then(res => {
                setSearchedOffer(res.data);
                setNoResults(false);
            })
            .catch(err => {
                if (err?.response.status === 404) {
                    setSearchedOffer(null);
                    setNoResults(true);
                }
                else {
                    setError('Coś poszło nie tak, spróbuj ponownie później...');
                }
            })
    }

    const searchByAgent = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        const select = e.target as HTMLSelectElement;
        if (select.value === 'null') {
            axiosClient({
                method: 'get',
                url: `/offers-all`,
            })
                .then(res => {
                    setOffers(res.data);
                    setAreResultsByAgent(false);
                })
                .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'))
        }
        else {
            axiosClient({
                method: 'get',
                url: `/offers-by-agent/${select.value}`
            })
                .then(res => {
                    setOffersByAgent(res.data);
                    setAreResultsByAgent(true);
                })
                .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'));
        }
    }

    if (error) {
        return <p role='alert' aria-live='assertive' className='error'>{error}</p>
    }

    return (
        <main className={styles.main}>
            <form onSubmit={searchOffer} className={styles.searchBar}>
                <input className={styles.searchBar__input} type="text" placeholder='Numer oferty' />
                <button type='submit' className={styles.searchBar__button}>Wyszukaj</button>
                <Link to='/oferty/new' className={styles.searchBar__button}>Dodaj ofertę</Link>
                {(agents && agents.length > 0) &&
                    <select onChange={searchByAgent} className={styles.searchBar__select}>
                        <option value="null">Szukaj wg agenta</option>
                        {
                            agents.map(agent => {
                                return <option key={agent.id} value={agent.id}>{agent.imie} {agent.nazwisko}</option>
                            })
                        }
                    </select>
                }
            </form>
            {
                noResults && <p className={styles.noResults}>Nie znaleziono oferty</p>
            }
            {
                searchedOffer &&
                <div className={styles.searchedContainer}>
                    <OfferTile
                        key={searchedOffer.id}
                        id={searchedOffer.id}
                        miasto={searchedOffer.miasto}
                        typ_nieruchomosci={searchedOffer.typ_nieruchomosci}
                        rodzaj={searchedOffer.rodzaj}
                        cena={searchedOffer.cena}
                        aktywna={searchedOffer.aktywna}
                        numer={searchedOffer.numer_oferty}
                        client_id={searchedOffer.client_id}
                    />
                </div>
            }
            <section className={styles.section}>
                {areResultsByAgent ?
                    offersByAgent!.map(offer => {
                        return <OfferTile
                            key={offer.id}
                            id={offer.id}
                            miasto={offer.miasto}
                            typ_nieruchomosci={offer.typ_nieruchomosci}
                            rodzaj={offer.rodzaj}
                            cena={offer.cena}
                            aktywna={offer.aktywna}
                            numer={offer.numer_oferty}
                            client_id={offer.client_id}
                        />
                    })
                    :
                    !offers?.data ? <img className='loading' src="/loading.gif" alt="ładowanie" /> : offers.data.map(offer => {
                        return <OfferTile
                            key={offer.id}
                            id={offer.id}
                            miasto={offer.miasto}
                            typ_nieruchomosci={offer.typ_nieruchomosci}
                            rodzaj={offer.rodzaj}
                            cena={offer.cena}
                            aktywna={offer.aktywna}
                            numer={offer.numer_oferty}
                            client_id={offer.client_id}
                        />
                    })
                }
            </section>
            {
                !areResultsByAgent &&
                <>
                    <div className={styles.pagination}>
                        <button onClick={() => setPage(1)} disabled={page === 1 ? true : false} aria-disabled={page === 1 ? true : false} className={`${styles.pagination__button} ${page === 1 && styles.pagination__button_disabled}`}>
                            <BsFillSkipStartFill />
                        </button>
                        <button onClick={() => setPage(prev => prev - 1)} disabled={page === 1 ? true : false} aria-disabled={page === 1 ? true : false} className={`${styles.pagination__button} ${page === 1 && styles.pagination__button_disabled}`}>
                            <GrPrevious />
                        </button>
                        <button onClick={() => setPage(prev => prev + 1)} disabled={page === offers?.last_page ? true : false} aria-disabled={page === offers?.last_page ? true : false} className={`${styles.pagination__button} ${page === offers?.last_page && styles.pagination__button_disabled}`}>
                            <GrNext />
                        </button>
                        <button onClick={() => setPage(offers!.last_page)} disabled={page === offers?.last_page ? true : false} aria-disabled={page === offers?.last_page ? true : false} className={`${styles.pagination__button} ${page === offers?.last_page && styles.pagination__button_disabled}`}>
                            <BsFillSkipEndFill />
                        </button>
                    </div>
                    <p className={styles.page}>Strona: {page}</p>
                </>
            }
        </main >
    )
}

export default Oferty
