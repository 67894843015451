import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layouts/Layout";
import { Login, Ogolne, Tresc, Kontakt, Oferty, OfertaForm, Agenci, AgentForm, Klienci, KlientForm, NotFound } from './pages';
import { AuthProvider } from "./contexts/AuthProvider";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Ogolne />} />
            <Route path="/tresc" element={<Tresc />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="/oferty" element={<Oferty />} />
            <Route path="/oferty/:id" element={<OfertaForm />} />
            <Route path="/oferty/new" element={<OfertaForm />} />
            <Route path="/agenci" element={<Agenci />} />
            <Route path="/agenci/:id" element={<AgentForm />} />
            <Route path="/agenci/new" element={<AgentForm />} />
            <Route path="/klienci" element={<Klienci />} />
            <Route path="/klienci/:id" element={<KlientForm />} />
            <Route path="/klienci/new" element={<KlientForm />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
