import { useState, useEffect, useRef, FormEvent, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import Notification from '../../components/notification/Notification';
import axiosClient from '../../axiosClient';
import axios from 'axios';


import styles from './agentForm.module.css';

interface Agent {
    id: number;
    imie: string;
    nazwisko: string;
    zdjecie: string;
    zawod: string;
    nr_telefonu: string;
    email: string;
    created_at: string;
    updated_at: string;
}

const AgentForm = () => {
    const [agent, setAgent] = useState<Agent | null>(null);
    const [image, setImage] = useState<string>('/image-placeholder.png');
    const [notification, setNotification] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [validationError, setValidationError] = useState<string | null>(null);
    const { id } = useParams();
    const imieRef = useRef<HTMLInputElement>(null);
    const nazwiskoRef = useRef<HTMLInputElement>(null);
    const zawodRef = useRef<HTMLInputElement>(null);
    const nrTelefonuRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);



    useEffect(() => {
        if (!id) return;
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: `/agent/${id}`,
            cancelToken: source.token
        })
            .then(res => {
                const data = res.data;
                setAgent(data);
                setError(null);
                setImage(`${process.env.REACT_APP_BACKEND_URL}/storage/agents/${data.zdjecie}`)
                imieRef.current!.value = data.imie;
                nazwiskoRef.current!.value = data.nazwisko;
                zawodRef.current!.value = data.zawod;
                nrTelefonuRef.current!.value = data.nr_telefonu;
                emailRef.current!.value = data.email;
            })
            .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'));

        return () => {
            source.cancel();
        }

    }, []);

    const editAgent = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const imageInput = form.querySelector('#image') as HTMLInputElement;
        const image = imageInput.files![0] as Blob;
        const data = new FormData();
        data.append('zdjecie', image);
        data.append('imie', imieRef.current!.value);
        data.append('nazwisko', nazwiskoRef.current!.value);
        data.append('zawod', zawodRef.current!.value);
        data.append('nr_telefonu', nrTelefonuRef.current!.value);
        data.append('email', emailRef.current!.value);
        data.append('_method', 'put');

        axiosClient({
            method: 'post',
            url: `/agents/${id}`,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data
        })
            .then(res => {
                setValidationError(null);
                setError(null);
                setNotification('Zapisano agenta');
                setTimeout(() => setNotification(null), 4000);
            })
            .catch(err => {
                if (err?.response.status === 422) {
                    setValidationError(err.response.data.message);
                }
                else {
                    setError('Coś poszło nie tak, spróbuj ponownie później...');
                }
            })

    }

    const addAgent = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const imageInput = form.querySelector('#image') as HTMLInputElement;
        const image = imageInput.files![0] as Blob;
        const data = new FormData();
        data.append('zdjecie', image);
        data.append('imie', imieRef.current!.value);
        data.append('nazwisko', nazwiskoRef.current!.value);
        data.append('zawod', zawodRef.current!.value);
        data.append('nr_telefonu', nrTelefonuRef.current!.value);
        data.append('email', emailRef.current!.value);

        axiosClient({
            method: 'post',
            url: '/agents',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data
        })
            .then(res => {
                form.reset();
                setImage('/image-placeholder.png');
                setValidationError(null);
                setError(null);
                setNotification('Dodano agenta');
                setTimeout(() => setNotification(null), 4000);
            })
            .catch(err => {
                if (err?.response.status === 422) {
                    setValidationError(err.response.data.message);
                }
                else {
                    setError('Coś poszło nie tak, spróbuj ponownie później...');
                }
            })

    }

    const setTempImage = (e: ChangeEvent<HTMLInputElement>): void => {
        const input = e.target as HTMLInputElement;
        const file = input.files && input.files[0];
        if (file && file.type.includes('image/')) {
            setImage(URL.createObjectURL(file));
        }
        else {
            alert('Wybierz plik, który jest zdjęciem');
            return;
        }
    }

    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <form encType='multipart/form-data' onSubmit={agent ? editAgent : addAgent} className={styles.form}>
            <Notification>{notification}</Notification>
            <label className={styles.form__imgLabel} htmlFor="image">
                <img className={styles.form__img} src={image} alt={agent ? `${agent.imie} ${agent.nazwisko}` : 'obrazek'} />
            </label>
            <input onChange={setTempImage} id='image' style={{ display: 'none' }} type="file" />
            <div className={styles.form__top}>
                <div className={styles.form__container}>
                    <label className={styles.form__label} htmlFor="imie">Imię</label>
                    <input ref={imieRef} className={styles.form__input} id='imie' type="text" />
                </div>
                <div className={styles.form__container}>
                    <label className={styles.form__label} htmlFor="nazwisko">Nazwisko</label>
                    <input ref={nazwiskoRef} className={styles.form__input} id='nazwisko' type="text" />
                </div>
                <div className={styles.form__container}>
                    <label className={styles.form__label} htmlFor="zawod">Zawód</label>
                    <input ref={zawodRef} className={styles.form__input} id='zawod' type="text" />
                </div>
            </div>
            <div className={styles.form__bottom}>
                <div className={styles.form__container}>
                    <label className={styles.form__label} htmlFor="nr_tel">Nr telefonu</label>
                    <input ref={nrTelefonuRef} className={styles.form__input} id='nr_tel' type="text" />
                </div>
                <div className={styles.form__container}>
                    <label className={styles.form__label} htmlFor="email">Email</label>
                    <input ref={emailRef} className={styles.form__input} id='email' type="email" />
                </div>
            </div>
            {
                validationError &&
                <p role='alert' aria-live='assertive' className={styles.form__error}>{validationError}</p>
            }
            <button className={styles.form__button}>Zapisz</button>
        </form>
    )
}

export default AgentForm
