

import styles from './notification.module.css';

interface Props {
    children: string | null;
}

const Notification = ({ children }: Props) => {
    const notification = children;
    return (
        <p role='alert' aria-live={notification ? 'assertive' : 'off'} className={`${styles.notification} ${notification && styles.notification_active}`}>{notification && notification}</p>
    )
}

export default Notification
