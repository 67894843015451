import { useState, useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import axiosClient from '../../axiosClient';
import { AuthContext, ContextInterface } from '../../contexts/AuthProvider';
import { GiHamburgerMenu } from 'react-icons/gi';

import styles from './header.module.css';

const Header = () => {
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const { setIsAuthorized } = useContext<ContextInterface>(AuthContext)
    const [isNavActive, setIsNavActive] = useState<boolean>(false);

    const logout = (): void => {
        axiosClient({
            method: 'post',
            url: '/logout'
        })
            .then(res => {
                setIsAuthorized(false);
                localStorage.removeItem('token');
                navigate('/login');
            })
            .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'));
    }

    const toggleNav = (): void => {
        setIsNavActive(prev => {
            if (prev) {
                document.body.style.overflow = 'auto';
            }
            else {
                document.body.style.overflow = 'hidden';
            }
            return !prev;
        });
    }

    const navOff = (): void => {
        setIsNavActive(false);
        document.body.style.overflow = 'auto';
    }

    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <>
            <header className={styles.header}>
                <NavLink onClick={navOff} to='/' className={({ isActive }) =>
                    `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Ogólne</NavLink>
                <NavLink onClick={navOff} to='/tresc' className={({ isActive }) =>
                    `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Treść</NavLink>
                <NavLink onClick={navOff} to='/kontakt' className={({ isActive }) =>
                    `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Kontakt</NavLink>
                <NavLink onClick={navOff} to='/oferty' className={({ isActive }) =>
                    `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Oferty</NavLink>
                <NavLink onClick={navOff} to='/agenci' className={({ isActive }) =>
                    `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Agenci</NavLink>
                <NavLink onClick={navOff} to='/klienci' className={({ isActive }) =>
                    `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Klienci</NavLink>
                <button onClick={logout} className={styles.header__logout}>
                    <FiLogOut />
                </button>
            </header>
            <header className={styles.header_mobile}>
                <div className={styles.header_mobile__top}>
                    <button onClick={toggleNav} className={`${styles.header_mobile__hamburger} ${isNavActive && styles.header_mobile__hamburger_active}`}>
                        <GiHamburgerMenu />
                    </button>
                </div>
                <nav className={`${styles.header_mobile__nav} ${isNavActive && styles.header_mobile__nav_active}`}>
                    <NavLink onClick={navOff} to='/' className={({ isActive }) =>
                        `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Ogólne</NavLink>
                    <NavLink onClick={navOff} to='/tresc' className={({ isActive }) =>
                        `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Treść</NavLink>
                    <NavLink onClick={navOff} to='/kontakt' className={({ isActive }) =>
                        `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Kontakt</NavLink>
                    <NavLink onClick={navOff} to='/oferty' className={({ isActive }) =>
                        `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Oferty</NavLink>
                    <NavLink onClick={navOff} to='/agenci' className={({ isActive }) =>
                        `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Agenci</NavLink>
                    <NavLink onClick={navOff} to='/klienci' className={({ isActive }) =>
                        `${styles.header__navLink} ${isActive && styles.header__navLink_active}`}>Klienci</NavLink>
                    <button onClick={logout} className={styles.header__logout}>
                        <FiLogOut />
                    </button>
                </nav>
            </header>
        </>
    )
}

export default Header
