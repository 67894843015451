import { useState, useEffect, useRef, FormEvent } from 'react';
import axiosClient from '../../axiosClient';
import axios from 'axios';
import Notification from '../../components/notification/Notification';

import styles from './kontakt.module.css';

interface Response {
    id: number;
    location: string;
    key: string;
    value: string;
    created_at: string;
    updated_at: string;
}

const Kontakt = () => {
    const miastoRef = useRef<HTMLInputElement>(null);
    const adresRef = useRef<HTMLInputElement>(null);
    const godzinyOtwarciaRef = useRef<HTMLInputElement>(null);
    const telFaxRef = useRef<HTMLInputElement>(null);
    const mobileRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const godzinyOtwarciaTextRef = useRef<HTMLTextAreaElement>(null);
    const [notification, setNotification] = useState<string | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: '/contact',
            cancelToken: source.token
        })
            .then(res => {
                const data: Response[] = res.data;
                const miasto = data.filter(item => {
                    return item.key === 'siedzibaMiasto';
                })[0].value;
                const adres = data.filter(item => {
                    return item.key === 'siedzibaAdres';
                })[0].value;
                const godzinyOtwarcia = data.filter(item => {
                    return item.key === 'godzinyPracy';
                })[0].value;
                const telFax = data.filter(item => {
                    return item.key === 'telFax';
                })[0].value;
                const mobile = data.filter(item => {
                    return item.key === 'mobile';
                })[0].value;
                const email = data.filter(item => {
                    return item.key === 'email';
                })[0].value;
                const description = data.filter(item => {
                    return item.key === 'description';
                })[0].value;
                const godzinyOtwarciaText = data.filter(item => {
                    return item.key === 'godzinyPracyText';
                })[0].value;

                miastoRef.current!.value = miasto;
                adresRef.current!.value = adres;
                godzinyOtwarciaRef.current!.value = godzinyOtwarcia;
                telFaxRef.current!.value = telFax;
                mobileRef.current!.value = mobile;
                emailRef.current!.value = email;
                descriptionRef.current!.value = description;
                godzinyOtwarciaTextRef.current!.value = godzinyOtwarciaText;

            })
            .catch(err => console.log(err));

        return () => {
            source.cancel();
        }

    }, []);

    const changeContent = (e: FormEvent<HTMLFormElement>, key: string): void => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const data = form.querySelector('[data-id="data"]') as HTMLInputElement | HTMLTextAreaElement;

        axiosClient({
            method: 'put',
            url: '/content',
            data: {
                location: 'contact',
                key,
                value: data?.value || ''
            }
        })
            .then(res => {
                setNotification(res.data.message);
                setTimeout(() => setNotification(null), 4000);
            })
            .catch(err => console.log(err));

    }

    const changeAdress = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const miasto = miastoRef.current as HTMLInputElement;
        const adres = adresRef.current as HTMLInputElement;

        axiosClient({
            method: 'put',
            url: '/content',
            data: {
                location: 'contact',
                key: 'siedzibaMiasto',
                value: miasto?.value || ''
            }
        })
            .then(res => {
                axiosClient({
                    method: 'put',
                    url: '/content',
                    data: {
                        location: 'contact',
                        key: 'siedzibaAdres',
                        value: adres?.value
                    }
                })
                    .then(res => {
                        setNotification(res.data.message);
                        setTimeout(() => setNotification(null), 4000);
                    })
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));

    }

    return (
        <main className={styles.main}>
            <Notification>{notification}</Notification>
            <section className={styles.section}>
                <h2 className={styles.section__heading}>Siedziba firmy</h2>
                <form onSubmit={changeAdress} className={styles.form}>
                    <div className={styles.form__container}>
                        <label className={styles.form__label} htmlFor="miasto">Miasto</label>
                        <input ref={miastoRef} id='miasto' className={styles.form__input} type="text" />
                    </div>
                    <div className={styles.form__container}>
                        <label className={styles.form__label} htmlFor="adres">Adres</label>
                        <input ref={adresRef} id='adres' className={styles.form__input} type="text" />
                    </div>
                    <button className={styles.form__button}>Zapisz</button>
                </form>
            </section>
            <section className={styles.section}>
                <h2 className={styles.section__heading}><label htmlFor="godzinyOtwarcia">Godziny otwarcia biura</label></h2>
                <form onSubmit={(e) => changeContent(e, 'godzinyPracy')} className={styles.form}>
                    <input data-id='data' ref={godzinyOtwarciaRef} className={styles.form__input} id='godzinyOtwarcia' type="text" />
                    <button className={styles.form__button}>Zapisz</button>
                </form>
            </section>
            <section className={styles.section}>
                <h2 className={styles.section__heading}><label htmlFor="godzinyOtwarciaText">Godziny otwarcia biura tekst</label></h2>
                <form onSubmit={(e) => changeContent(e, 'godzinyPracyText')} className={styles.form_column}>
                    <textarea data-id='data' ref={godzinyOtwarciaTextRef} className={styles.form__textarea} id='godzinyOtwarciaText' cols={35} rows={10}></textarea>
                    <button className={styles.form__button}>Zapisz</button>
                </form>
            </section>
            <section className={styles.section}>
                <h2 className={styles.section__heading}><label htmlFor="telFax">Tel/fax</label></h2>
                <form onSubmit={(e) => changeContent(e, 'telFax')} className={styles.form}>
                    <input data-id='data' ref={telFaxRef} className={styles.form__input} id='telFax' type="text" />
                    <button className={styles.form__button}>Zapisz</button>
                </form>
            </section>
            <section className={styles.section}>
                <h2 className={styles.section__heading}><label htmlFor="mobile">Telefon komórkowy</label></h2>
                <form onSubmit={(e) => changeContent(e, 'mobile')} className={styles.form}>
                    <input data-id='data' ref={mobileRef} className={styles.form__input} id='mobile' type="text" />
                    <button className={styles.form__button}>Zapisz</button>
                </form>
            </section>
            <section className={styles.section}>
                <h2 className={styles.section__heading}><label htmlFor="email">Email</label></h2>
                <form onSubmit={(e) => changeContent(e, 'email')} className={styles.form}>
                    <input data-id='data' ref={emailRef} className={styles.form__input} id='email' type="email" />
                    <button className={styles.form__button}>Zapisz</button>
                </form>
            </section>
            <section className={styles.section}>
                <h2 className={styles.section__heading}><label htmlFor="description">Opis SEO formularza kontaktowego w google</label></h2>
                <form onSubmit={(e) => changeContent(e, 'description')} className={styles.form_column}>
                    <textarea data-id='data' ref={descriptionRef} className={styles.form__textarea} id="description" cols={35} rows={10}></textarea>
                    <button className={styles.form__button}>Zapisz</button>
                </form>
            </section>
        </main>
    )
}

export default Kontakt
