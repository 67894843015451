

import styles from './notFound.module.css';

const NotFound = () => {
    return (
        <>
            not found
        </>
    )
}

export default NotFound
