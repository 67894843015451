import { useContext } from 'react';
import { AuthContext, ContextInterface } from '../contexts/AuthProvider';
import { Navigate, Outlet } from "react-router-dom"
import Header from '../components/header/Header';

const Layout = () => {
    const { isAuthorized, isLoading } = useContext<ContextInterface>(AuthContext);

    return (
        <>
            {
                isLoading ? <img className='loading' src="/loading.gif" alt="ładowanie" /> :
                    <>
                        {
                            !isAuthorized ? <Navigate to='/login' /> :
                                <>
                                    <Header />
                                    <Outlet />
                                </>
                        }
                    </>
            }

        </>
    )
}

export default Layout
