
import { useState } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import axiosClient from '../../axiosClient';
import Notification from '../notification/Notification';

import styles from './clientTile.module.css';

interface Props {
    id: number;
    imie: string;
    nazwisko: string;
    nazwa_firmy: string | null;
    nr_telefonu: string;
    email: string;
}

const ClientTile = (props: Props) => {
    const [notification, setNotification] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);


    const deleteClient = (id: number): void => {
        const confirmation = window.confirm('Czy na pewno chcesz usunąć klienta? Wszystkie oferty, które do niego należą pojawią się jako aktywne na stronie!');
        if (confirmation) {
            axiosClient({
                method: 'delete',
                url: `/clients/${id}`
            })
                .then(res => {
                    setNotification('Usunięto klienta');
                    setTimeout(() => setNotification(null), 4000);
                })
                .catch(err => {
                    setError('Coś poszło nie tak, spróbuj ponownie później...');
                })
        }
    }

    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <article className={styles.client}>
            <Notification>{notification}</Notification>
            <h3 className={styles.client__name}>{props.imie} {props.nazwisko}</h3>
            {props.nazwa_firmy &&
                <p className={styles.client__text}>{props.nazwa_firmy}</p>
            }
            <p className={styles.client__text}><a href={`tel:${props.nr_telefonu}`}>{props.nr_telefonu}</a></p>
            <p className={styles.client__text}><a href={`mailto:${props.email}`}>{props.email}</a></p>
            <div className={styles.client__buttons}>
                <Link className={styles.client__button} to={`/klienci/${props.id}`}>
                    <AiOutlineEdit className={styles.client__edit} />
                </Link>
                <button onClick={() => deleteClient(props.id)} className={styles.client__button}>
                    <AiOutlineDelete className={styles.client__delete} />
                </button>
            </div>
        </article>
    )
}

export default ClientTile
