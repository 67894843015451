import { useState, useEffect, useRef, FormEvent } from 'react';
import Notification from '../../components/notification/Notification';
import axiosClient from '../../axiosClient';
import axios from 'axios';

import styles from './tresc.module.css';

interface Response {
    id: number;
    location: string;
    key: string;
    value: string;
    created_at: string;
    updated_at: string;
}

const Tresc = () => {
    const titleRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const heroTextRef = useRef<HTMLTextAreaElement>(null);
    const heroSubHeadingRef = useRef<HTMLInputElement>(null);
    const heroHeadingRef = useRef<HTMLInputElement>(null);
    const heroLinkTextRef = useRef<HTMLInputElement>(null);
    const heroLinkDestinationRef = useRef<HTMLInputElement>(null);
    const homeSearchHeadingRef = useRef<HTMLInputElement>(null);
    const homeSearchItemsRef = useRef<HTMLInputElement>(null);
    const homeInvitationHeadingRef = useRef<HTMLInputElement>(null);
    const homeInvitationTextRef = useRef<HTMLTextAreaElement>(null);
    const footerTextRef = useRef<HTMLTextAreaElement>(null);
    const politykaPrywatnosciRef = useRef<HTMLTextAreaElement>(null);
    const [notification, setNotification] = useState<string | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: '/content',
            cancelToken: source.token
        })
            .then(res => {
                const data: Response[] = res.data;
                const title = data.filter(item => {
                    return item.key === 'title';
                })[0].value;
                const description = data.filter(item => {
                    return item.key === 'description';
                })[0].value;
                const heroText = data.filter(item => {
                    return item.key === 'heroText';
                })[0].value;
                const heroSubHeading = data.filter(item => {
                    return item.key === 'heroSubHeading';
                })[0].value;
                const heroHeading = data.filter(item => {
                    return item.key === 'heroHeading';
                })[0].value;
                const heroLinkText = data.filter(item => {
                    return item.key === 'heroLinkText';
                })[0].value;
                const heroLinkDestination = data.filter(item => {
                    return item.key === 'heroLinkDestination';
                })[0].value;
                const homeSearchHeading = data.filter(item => {
                    return item.key === 'homeSearchHeading';
                })[0].value;
                const homeSearchItems = data.filter(item => {
                    return item.key === 'homeSearchItems';
                })[0].value;
                const homeInvitationHeading = data.filter(item => {
                    return item.key === 'homeInvitationHeading';
                })[0].value;
                const homeInvitationText = data.filter(item => {
                    return item.key === 'homeInvitationText';
                })[0].value;
                const footerText = data.filter(item => {
                    return item.key === 'text' && item.location === 'footer';
                })[0].value;
                const politykaPrywatnosci = data.filter(item => {
                    return item.key === 'content' && item.location === 'polityka-prywatnosci';
                })[0].value;


                titleRef.current!.value = title;
                descriptionRef.current!.value = description;
                heroTextRef.current!.value = heroText;
                heroSubHeadingRef.current!.value = heroSubHeading;
                heroHeadingRef.current!.value = heroHeading;
                heroLinkTextRef.current!.value = heroLinkText;
                heroLinkDestinationRef.current!.value = heroLinkDestination;
                homeSearchHeadingRef.current!.value = homeSearchHeading;
                homeSearchItemsRef.current!.value = homeSearchItems;
                homeInvitationHeadingRef.current!.value = homeInvitationHeading;
                homeInvitationTextRef.current!.value = homeInvitationText;
                footerTextRef.current!.value = footerText;
                politykaPrywatnosciRef.current!.value = politykaPrywatnosci;
            })
            .catch(err => console.error(err));

        return () => {
            source.cancel();
        }
    }, []);

    const changeContent = (e: FormEvent<HTMLFormElement>, location: string, key: string) => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const data = form.querySelector('[data-id="data"]') as HTMLInputElement | HTMLTextAreaElement;

        axiosClient({
            method: 'put',
            url: '/content',
            data: {
                location,
                key,
                value: data?.value || ''
            }
        })
            .then(res => {
                setNotification(res.data.message);
                setTimeout(() => setNotification(null), 4000);
            })
            .catch(err => console.log(err));
    }

    function changeImg(e: FormEvent<HTMLFormElement>, key: string): void {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const input = form.querySelector('[data-id="data"]') as HTMLInputElement;
        const image = input.files![0] as Blob;
        const data = new FormData();
        data.append('image', image);
        data.append('key', key)
        if (image) {
            axiosClient({
                method: 'post',
                url: '/img',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data
            })
                .then(res => {
                    setNotification(res.data.message);
                    setTimeout(() => setNotification(null), 4000);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    return (
        <main className={styles.main}>
            <Notification>{notification}</Notification>
            <h1 className={styles.heading}>Zmiana treści na stronie</h1>
            <form onSubmit={(e) => changeContent(e, 'homepage', 'title')} className={styles.form}>
                <label className={styles.form__label} htmlFor="title">Tytuł SEO strony w google</label>
                <input data-id='data' id='title' ref={titleRef} className={styles.form__input} type="text" />
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'homepage', 'description')} className={styles.form}>
                <label className={styles.form__label} htmlFor="description">Opis SEO strony w google</label>
                <textarea data-id='data' ref={descriptionRef} className={styles.form__textarea} id="description" cols={35} rows={10}></textarea>
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form className={styles.form} onSubmit={(e) => changeContent(e, 'polityka-prywatnosci', 'content')}>
                <label htmlFor='politykaPrywatnosci' className={styles.form__label}>Treść regulaminu i polityki prywatności</label>
                <textarea data-id='data' id="politykaPrywatnosci" className={`${styles.form__textarea} ${styles.form__textarea_politykaPrywatnosci}`} ref={politykaPrywatnosciRef} cols={35} rows={20}></textarea>
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'homepage', 'heroSubHeading')} className={styles.form}>
                <img className={styles.form__image} src="img/heroSubHeading.png" alt="mini nagłówek sekcji hero homepage" />
                <input data-id='data' ref={heroSubHeadingRef} className={styles.form__input} aria-label='mini nagłówek sekcji hero homepage' type="text" />
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'homepage', 'heroHeading')} className={styles.form}>
                <img className={styles.form__image} src="img/heroHeading.png" alt="nagłówek sekcji hero homepage" />
                <input data-id='data' ref={heroHeadingRef} className={styles.form__input} aria-label='nagłówek sekcji hero homepage' type="text" />
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'homepage', 'heroText')} className={styles.form}>
                <img className={styles.form__image} src="img/heroText.png" alt="tekst w sekcji hero homepage" />
                <textarea data-id='data' ref={heroTextRef} aria-label='tekst w sekcji hero homepage' className={styles.form__textarea} cols={35} rows={10}></textarea>
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'homepage', 'heroLinkText')} className={styles.form}>
                <img className={styles.form__image} src="img/heroLinkText.png" alt="tekst linku w sekcji hero homepage" />
                <input data-id='data' ref={heroLinkTextRef} className={styles.form__input} aria-label='tekst linku w sekcji hero homepage' type="text" />
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'homepage', 'heroLinkDestination')} className={styles.form}>
                <label className={styles.form__label} htmlFor="heroLinkDestination">Odnośnik linku w sekcji hero</label>
                <input data-id='data' id='heroLinkDestination' ref={heroLinkDestinationRef} className={styles.form__input} type="text" />
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form encType='multipart/form-data' onSubmit={(e) => changeImg(e, 'heroImg')} className={styles.form}>
                <img className={styles.form__image} src="img/heroImg.png" alt="obraz w sekcji hero homepage" />
                <input data-id='data' className={styles.form__input} aria-label='obraz w sekcji hero homepage' type="file" />
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'homepage', 'homeSearchHeading')} className={styles.form}>
                <img className={styles.form__image} src="img/homeSearchHeading.png" alt="nagłówek wyszukiwarki homepage" />
                <input data-id='data' ref={homeSearchHeadingRef} className={styles.form__input} aria-label='nagłówek wyszukiwarki homepage' type="text" />
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'homepage', 'homeSearchItems')} className={styles.form}>
                <img className={styles.form__image} src="img/homeSearchItems.png" alt="lista przy wyszukiwarce homepage" />
                <p className={styles.form__label_small}>Trzeba wpisać po przecinku</p>
                <input data-id='data' ref={homeSearchItemsRef} className={styles.form__input} aria-label='lista przy wyszukiwarce homepage' type="text" />
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'homepage', 'homeInvitationHeading')} className={styles.form}>
                <img className={styles.form__image} src="img/homeInvitationHeading.png" alt="nagłówek przy zaproszeniu do biura homepage" />
                <input data-id='data' ref={homeInvitationHeadingRef} className={styles.form__input} aria-label='nagłówek przy zaproszeniu do biura homepage' type="text" />
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'homepage', 'homeInvitationText')} className={styles.form}>
                <img className={styles.form__image} src="img/homeInvitationText.png" alt="tekst przy zaproszeniu do biura homepage" />
                <textarea data-id='data' ref={homeInvitationTextRef} aria-label='tekst przy zaproszeniu do biura homepage' className={styles.form__textarea} cols={35} rows={10}></textarea>
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form encType='multipart/form-data' onSubmit={(e) => changeImg(e, 'homeInvitationImg')} className={styles.form}>
                <img className={styles.form__image} src="img/homeInvitationImg.png" alt="obraz przy zaproszeniu do biura homepage" />
                <input data-id='data' className={styles.form__input} aria-label='obraz przy zaproszeniu do biura homepage' type="file" />
                <button className={styles.form__button}>Zapisz</button>
            </form>

            <form onSubmit={(e) => changeContent(e, 'footer', 'text')} className={styles.form}>
                <img className={styles.form__image} src="img/footerText.png" alt="tekst w stopce" />
                <textarea data-id='data' ref={footerTextRef} aria-label='tekst w stopce' className={styles.form__textarea} cols={35} rows={10}></textarea>
                <button className={styles.form__button}>Zapisz</button>
            </form>
        </main>
    )
}

export default Tresc
