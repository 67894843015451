import { useState, useEffect, useRef, FormEvent } from 'react';
import Notification from '../../components/notification/Notification';
import axiosClient from '../../axiosClient';
import axios from 'axios';
import { useParams } from 'react-router-dom';


import styles from './klientForm.module.css';

const KlientForm = () => {
    const { id } = useParams();
    const [notification, setNotification] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [validationError, setValidationError] = useState<string | null>(null);
    const imieRef = useRef<HTMLInputElement>(null);
    const nazwiskoRef = useRef<HTMLInputElement>(null);
    const firmaRef = useRef<HTMLInputElement>(null);
    const nrTelefonuRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!id) return;
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: `/clients/${id}`,
            cancelToken: source.token
        })
            .then(res => {
                const data = res.data;
                imieRef.current!.value = data.imie;
                nazwiskoRef.current!.value = data.nazwisko;
                firmaRef.current!.value = data.nazwa_firmy || '';
                nrTelefonuRef.current!.value = data.nr_telefonu;
                emailRef.current!.value = data.email;
            })
            .catch(err => {
                setError('Coś poszło nie tak, spróbuj ponownie później...');
            })

        return () => {
            source.cancel();
        }

    }, []);

    const addClient = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        axiosClient({
            method: 'post',
            url: '/clients',
            data: {
                imie: imieRef.current!.value,
                nazwisko: nazwiskoRef.current!.value,
                firma: firmaRef.current!.value || '',
                nr_telefonu: nrTelefonuRef.current!.value,
                email: emailRef.current!.value,
            }
        })
            .then(res => {
                form.reset();
                setValidationError(null);
                setNotification('Dodano klienta');
                setTimeout(() => setNotification(null), 4000);
            })
            .catch(err => {
                if (err?.response.status === 422) {
                    setValidationError(err.response.data.message);
                }
                else {
                    setError('Coś poszło nie tak, spróbuj ponownie później...');
                }
            })

    }

    const editClient = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        axiosClient({
            method: 'put',
            url: `/clients/${id}`,
            data: {
                imie: imieRef.current!.value,
                nazwisko: nazwiskoRef.current!.value,
                firma: firmaRef.current!.value || '',
                nr_telefonu: nrTelefonuRef.current!.value,
                email: emailRef.current!.value,
            }
        })
            .then(res => {
                setValidationError(null);
                setNotification('Zapisano klienta');
                setTimeout(() => setNotification(null), 4000);
            })
            .catch(err => {
                if (err?.response.status === 422) {
                    setValidationError(err.response.data.message);
                }
                else {
                    setError('Coś poszło nie tak, spróbuj ponownie później...');
                }
            })

    }


    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }


    return (
        <form onSubmit={id ? editClient : addClient} className={styles.form}>
            <Notification>{notification}</Notification>
            <div className={styles.form__top}>
                <div className={styles.form__container}>
                    <label className={styles.form__label} htmlFor="imie">Imię</label>
                    <input ref={imieRef} className={styles.form__input} id='imie' type="text" />
                </div>
                <div className={styles.form__container}>
                    <label className={styles.form__label} htmlFor="nazwisko">Nazwisko</label>
                    <input ref={nazwiskoRef} className={styles.form__input} id='nazwisko' type="text" />
                </div>
                <div className={styles.form__container}>
                    <label className={styles.form__label} htmlFor="firma">Nazwa firmy(opcjonalne)</label>
                    <input ref={firmaRef} className={styles.form__input} id='firma' type="text" />
                </div>
            </div>
            <div className={styles.form__bottom}>
                <div className={styles.form__container}>
                    <label className={styles.form__label} htmlFor="nr_tel">Nr telefonu</label>
                    <input ref={nrTelefonuRef} className={styles.form__input} id='nr_tel' type="text" />
                </div>
                <div className={styles.form__container}>
                    <label className={styles.form__label} htmlFor="email">Email</label>
                    <input ref={emailRef} className={styles.form__input} id='email' type="email" />
                </div>
            </div>
            {
                validationError && <p className={styles.form__error}>{validationError}</p>
            }
            <button className={styles.form__button}>Zapisz</button>
        </form>
    )
}

export default KlientForm
