import { createContext, useState, useEffect, ReactNode } from 'react';
import axiosClient from '../axiosClient';
import axios from 'axios';

const AuthContext = createContext<ContextInterface>({
    isAuthorized: false,
    setIsAuthorized: () => false,
    isLoading: true
});

export interface ContextInterface {
    isAuthorized: boolean;
    setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
    isLoading: boolean;
}

interface Props {
    children: ReactNode
}

const AuthProvider = ({ children }: Props) => {
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();
        if (!localStorage.getItem('token')) {
            setIsAuthorized(false);
            setIsLoading(false);
            return;
        }

        axiosClient({
            method: 'get',
            url: '/is-authorized',
            cancelToken: source.token
        })
            .then(res => {
                setIsAuthorized(true);
            })
            .catch(err => {
                setIsAuthorized(false);
                if (err?.response?.status !== 401) {
                    setError('Coś poszło nie tak, spróbuj ponownie później...');
                }
            })
            .finally(() => {
                setIsLoading(false);
            })


        return () => {
            source.cancel();
        }

    }, []);


    const defaultValue: ContextInterface = {
        isAuthorized,
        setIsAuthorized,
        isLoading,
    }

    if (error) {
        return <p role='alert' aria-live='assertive' className='error'>{error}</p>
    }

    return (
        <AuthContext.Provider value={defaultValue}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider };
