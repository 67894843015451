import { useState } from 'react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Notification from '../notification/Notification';
import axiosClient from '../../axiosClient';

import styles from './agentTile.module.css';

interface Props {
    id: number;
    zdjecie: string;
    imieINazwisko: string;
    zawod: string;
}

const AgentTile = (props: Props) => {
    const [error, setError] = useState<string | null>(null);
    const [notification, setNotification] = useState<string | null>(null);

    const deleteAgent = (id: number): void => {
        const confirmation = window.confirm('Zostaną usunięte wszystkie oferty tego agenta! Czy na pewno chcesz kontynuować?');
        if (confirmation) {
            axiosClient({
                method: 'delete',
                url: `/agents/${id}`
            })
                .then(res => {
                    setNotification('Usunięto agenta z bazy');
                    setTimeout(() => setNotification(null), 4000);
                })
                .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'));
        }
    }

    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <article className={styles.agent}>
            <Notification>{notification}</Notification>
            <img className={styles.agent__img} src={`${process.env.REACT_APP_BACKEND_URL}/storage/agents/${props.zdjecie}`} alt={props.imieINazwisko} />
            <div className={styles.agent__right}>
                <h3 className={styles.agent__name}>{props.imieINazwisko}</h3>
                <p className={styles.agent__job}>{props.zawod}</p>
                <div className={styles.agent__buttons}>
                    <Link className={styles.agent__button} to={`/agenci/${props.id}`}>
                        <AiOutlineEdit className={styles.agent__edit} />
                    </Link>
                    <button onClick={() => deleteAgent(props.id)} className={styles.agent__button}>
                        <AiOutlineDelete className={styles.agent__delete} />
                    </button>
                </div>
            </div>
        </article>
    )
}

export default AgentTile
