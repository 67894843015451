import { useState, FormEvent, useContext, useEffect } from 'react';
import { AuthContext, ContextInterface } from '../../contexts/AuthProvider';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import Notification from '../../components/notification/Notification';
import axiosClient from '../../axiosClient';

import styles from './login.module.css';

const Login = () => {
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const { setIsAuthorized, isAuthorized, isLoading } = useContext<ContextInterface>(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (searchParams.get('message')) {
            setMessage(searchParams.get('message'));
            setTimeout(() => setMessage(null), 4000);
        }
    }, []);

    const togglePassword = (): void => {
        setIsPasswordVisible(prev => !prev);
    }

    const login = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        const form = e.target as HTMLFormElement;
        const login = form.querySelector('#login') as HTMLInputElement;
        const password = form.querySelector('#password') as HTMLInputElement;

        axiosClient({
            method: 'post',
            url: '/login',
            data: {
                login: login?.value,
                password: password?.value
            }
        })
            .then(res => {
                localStorage.setItem('token', res.data.token);
                setIsAuthorized(true);
                navigate('/');
            })
            .catch(err => setError(err?.response?.data.message));
    }

    return (
        <>
            {
                isLoading ? <img className='loading' src="/loading.gif" alt="ładowanie" /> :
                    <>
                        {isAuthorized ? <Navigate to='/' /> :
                            <form onSubmit={login} className={styles.form}>
                                <Notification>{message}</Notification>
                                <div className={styles.form__container}>
                                    <label htmlFor="login" className={styles.form__label}>Login</label>
                                    <input id='login' type="text" className={styles.form__input} />
                                </div>
                                <div className={styles.form__container}>
                                    <label htmlFor="password" className={styles.form__label}>Hasło</label>
                                    <input id='password' type={isPasswordVisible ? 'text' : 'password'} className={styles.form__input} />
                                    <button type='button' onClick={togglePassword} className={styles.form__container__eye}>
                                        {
                                            isPasswordVisible ? <AiFillEye /> : <AiFillEyeInvisible />
                                        }
                                    </button>
                                </div>
                                {
                                    error && <p role='alert' aria-live='assertive' className={styles.form__error}>{error}</p>
                                }
                                <button className={styles.form__button}>Zaloguj</button>
                            </form>
                        }
                    </>
            }
        </>
    )
}

export default Login
