import { useState, useEffect } from 'react';
import formatNumber from '../../utilities/formatNumber';
import { Link } from 'react-router-dom';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import axiosClient from '../../axiosClient';
import axios from 'axios';
import Notification from '../notification/Notification';

import styles from './offerTile.module.css';


interface Props {
    id: number;
    miasto: string;
    typ_nieruchomosci: string;
    rodzaj: string;
    cena: number;
    aktywna: number;
    numer: string;
    client_id: number | null;
}

const OfferTile = (props: Props) => {
    const [thumbnail, setThumbnail] = useState<string>('image-placeholder.png');
    const [notification, setNotification] = useState<string | null>(null);
    const [client, setClient] = useState<string>('');

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: `/thumbnail/${props.numer}`,
            cancelToken: source.token
        })
            .then(res => {
                setThumbnail(`${process.env.REACT_APP_BACKEND_URL}/storage/offers/${res.data}`);
                if (!props.aktywna) {
                    axiosClient({
                        method: 'get',
                        url: `/client-name/${props.client_id}`,
                        cancelToken: source.token
                    })
                        .then(res => {
                            setClient(res.data);
                        })
                        .catch(err => console.log(err));
                }
            })
            .catch(err => console.log(err));

        return () => {
            source.cancel();
        }

    }, []);

    const deleteOffer = (id: number): void => {
        const confirmation = window.confirm('Czy na pewno chcesz usunąć ofertę? Nie da się tego odwrócić!');
        if (confirmation) {
            axiosClient({
                method: 'delete',
                url: `/offers/${id}`
            })
                .then(res => {
                    setNotification('Usunięto ofertę z bazy');
                    setTimeout(() => setNotification(null), 4000);
                })
                .catch(err => console.log(err));
        }
    }

    return (
        <article className={styles.offer}>
            <Notification>{notification}</Notification>
            <img style={{ objectFit: thumbnail === 'image-placeholder.png' ? 'contain' : 'cover' }} src={thumbnail} alt="miniatura oferty" className={styles.offer__img} />
            <div className={styles.offer__right}>
                <h3 className={styles.offer__heading}>{props.miasto}</h3>
                <p className={styles.offer__text}>{props.typ_nieruchomosci} na {props.rodzaj}</p>
                <p className={styles.offer__price}>Cena: {formatNumber(props.cena)} PLN</p>
                <p className={styles.offer__text}>
                    {
                        props.aktywna ? 'Aktywna: tak' :
                            `Nabywca: ${client}`
                    }
                </p>
                <p className={styles.offer__text}>Numer: {props.numer}</p>
                <div className={styles.offer__buttons}>
                    <Link to={`/oferty/${props.id}`} className={styles.offer__button}>
                        <AiOutlineEdit className={styles.offer__edit} />
                    </Link>
                    <button onClick={() => { deleteOffer(props.id) }} className={styles.offer__button}>
                        <AiOutlineDelete className={styles.offer__delete} />
                    </button>
                </div>
            </div>
        </article>
    )
}

export default OfferTile
