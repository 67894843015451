import { useState, useEffect, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import Notification from '../../components/notification/Notification';
import axiosClient from '../../axiosClient';
import axios from 'axios';
import { MdAddBox } from 'react-icons/md';
import { AiOutlineDelete } from 'react-icons/ai';


import styles from './ofertaForm.module.css';

interface Offer {
    id: number;
    nazwa: string;
    miasto: string;
    dzielnica: string | null;
    numer_oferty: string;
    typ_nieruchomosci: string;
    rodzaj: string;
    rynek: string;
    liczba_pokoi: number | null;
    pietro: number | null;
    powierzchnia: number;
    cena_za_metr_kwadratowy: number;
    cena: number;
    rodzaj_budynku: string | null;
    standard: number | null;
    stan_lokalu: string | null;
    okna: string | null;
    balkon: number | null;
    opis: string;
    agent_id: number;
    client_id: number | null;
    aktywna: number;
    created_at: string;
    updated_at: string;
}

interface Image {
    id: number;
    url: string;
    offer_id: number;
    is_main: number;
    created_at: string;
    updated_at: string;
}

interface Agent {
    id: number;
    imie: string;
    nazwisko: string;
    zdjecie: string;
    zawod: string;
    nr_telefonu: string;
    email: string;
    created_at: string;
    updated_at: string;
}

interface Client {
    id: number;
    imie: string;
    nazwisko: string;
    nazwa_firmy: string | null;
    nr_telefonu: string;
    email: string;
    created_at: string;
    updated_at: string;
}

interface CustomProperty {
    key: string;
    value: string;
}

interface OfertaFormData {
    nazwa: string,
    miasto: string,
    dzielnica: string,
    dzielnicaCheckbox: boolean,
    numerOferty: string,
    typNieruchomosci: string,
    rodzaj: string,
    rynek: string,
    liczbaPokoi: string,
    liczbaPokoiCheckbox: boolean,
    pietro: string,
    pietroCheckbox: boolean,
    powierzchnia: string,
    cenaZaMetrKwadratowy: string,
    cena: string,
    rodzajBudynku: string,
    rodzajBudynkuCheckbox: boolean,
    standard: string,
    standardCheckbox: boolean,
    stanLokalu: string,
    stanLokaluCheckbox: boolean,
    okna: string,
    oknaCheckbox: boolean,
    balkonCheckbox: boolean,
    opis: string
}

const OfertaForm = () => {
    const { id } = useParams();
    const [customProperties, setCustomProperties] = useState<CustomProperty[]>([
        {
            key: '',
            value: ''
        }
    ]);
    const [invalidContainer, setInvalidContainer] = useState<string | null>(null);
    const [agents, setAgents] = useState<Agent[] | null>(null);
    const [ofertaFormData, setOfertaFormData] = useState<OfertaFormData>({ nazwa: '', miasto: '', dzielnica: '', dzielnicaCheckbox: false, numerOferty: '', typNieruchomosci: '', rodzaj: '', rynek: '', liczbaPokoi: '', liczbaPokoiCheckbox: false, pietro: '', pietroCheckbox: false, powierzchnia: '', cenaZaMetrKwadratowy: '', cena: '', rodzajBudynku: '', rodzajBudynkuCheckbox: false, standard: '', standardCheckbox: false, stanLokalu: '', stanLokaluCheckbox: false, okna: '', oknaCheckbox: false, balkonCheckbox: false, opis: '' });
    const [clients, setClients] = useState<Client[] | [] | null>(null);
    const [images, setImages] = useState<string[]>(['/image-placeholder.png']);
    const [isOfferActive, setIsOfferActive] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [featured, setFeatured] = useState<boolean>(false);
    const [notification, setNotification] = useState<string | null>(null);
    const [validationError, setValidationError] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    useEffect(() => {
        const source = axios.CancelToken.source();

        async function fetchData() {
            try {
                const { data } = await axiosClient({
                    method: 'get',
                    url: '/agents',
                    cancelToken: source.token
                });
                setAgents(data);
            } catch (err) {
                setError('Coś poszło nie tak, spróbuj ponownie później...');
            }

            try {
                const { data } = await axiosClient({
                    method: 'get',
                    url: '/clients',
                    cancelToken: source.token
                });
                setClients(data);
            } catch (err) {
                setError('Coś poszło nie tak, spróbuj ponownie później...');
            }

            if (id) {
                try {
                    const { data } = await axiosClient({
                        method: 'get',
                        url: `/edit-offer-data/${id}`,
                        cancelToken: source.token
                    });
                    const offer: Offer = data.offer;
                    setOfertaFormData({
                        nazwa: offer.nazwa,
                        miasto: offer.miasto,
                        dzielnica: offer.dzielnica || '',
                        dzielnicaCheckbox: offer.dzielnica ? false : true,
                        numerOferty: offer.numer_oferty,
                        typNieruchomosci: offer.typ_nieruchomosci,
                        rodzaj: offer.rodzaj,
                        rynek: offer.rynek,
                        liczbaPokoi: offer.liczba_pokoi ? offer.liczba_pokoi.toString() : '',
                        liczbaPokoiCheckbox: offer.liczba_pokoi ? false : true,
                        pietro: offer.pietro !== null ? offer.pietro.toString() : '',
                        pietroCheckbox: offer.pietro ? false : true,
                        powierzchnia: offer.powierzchnia.toString(),
                        cenaZaMetrKwadratowy: offer.cena_za_metr_kwadratowy.toString(),
                        cena: offer.cena.toString(),
                        rodzajBudynku: offer.rodzaj_budynku || '',
                        rodzajBudynkuCheckbox: offer.rodzaj_budynku ? false : true,
                        standard: offer.standard ? offer.standard.toString() : '',
                        standardCheckbox: offer.standard ? false : true,
                        stanLokalu: offer.stan_lokalu || '',
                        stanLokaluCheckbox: offer.stan_lokalu ? false : true,
                        okna: offer.okna || '',
                        oknaCheckbox: offer.okna ? false : true,
                        balkonCheckbox: offer.balkon === null ? true : false,
                        opis: offer.opis || ''
                    });

                    const balkonTak = document.querySelector('#balkonTak') as HTMLInputElement;
                    const balkonNie = document.querySelector('#balkonNie') as HTMLInputElement;
                    if (offer.balkon === 1) {
                        balkonTak.checked = true;
                    } else if (offer.balkon === 0) {
                        balkonNie.checked = true;
                    }

                    const agentRadio = document.querySelector(`#agent${offer.agent_id}`) as HTMLInputElement;
                    agentRadio.checked = true;
                    if (offer.aktywna) {
                        setIsOfferActive(true);
                    }
                    else {
                        setIsOfferActive(false);
                        setTimeout(() => {
                            const clientRadio = document.querySelector(`#klient${offer.client_id}`) as HTMLInputElement;
                            clientRadio.checked = true;
                        }, 1000);
                    }

                    const imagesArray: Image[] = data.images;
                    const urlArray = imagesArray.map(item => `${process.env.REACT_APP_BACKEND_URL}/storage/offers/${item.url}`);
                    setImages(urlArray);
                    setCustomProperties(data.custom_properties);
                    setFeatured(data.is_featured);
                } catch (err) {
                    setError('Coś poszło nie tak, spróbuj ponownie później...');
                }
            }
        }

        fetchData();

        return () => {
            source.cancel();
        }

    }, []);

    function handleInputChange(e: React.ChangeEvent) {
        const input = e.target as HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
        const id = input.id;

        setOfertaFormData(prev => {
            switch (id) {
                case 'nazwa':
                    return { ...prev, nazwa: input.value };
                    break;
                case 'miasto':
                    return { ...prev, miasto: input.value };
                    break;
                case 'dzielnica':
                    return { ...prev, dzielnica: input.value };
                    break;
                case 'dzielnicaCheckbox':
                    const dzielnica = input as HTMLInputElement;
                    return { ...prev, dzielnicaCheckbox: dzielnica.checked };
                    break;
                case 'numer_oferty':
                    return { ...prev, numerOferty: input.value };
                    break;
                case 'typ_nieruchomosci':
                    return { ...prev, typNieruchomosci: input.value };
                    break;
                case 'rodzaj':
                    return { ...prev, rodzaj: input.value };
                    break;
                case 'rynek':
                    return { ...prev, rynek: input.value };
                    break;
                case 'liczba_pokoi':
                    return { ...prev, liczbaPokoi: input.value };
                    break;
                case 'liczbaPokoiCheckbox':
                    const liczbaPokoi = input as HTMLInputElement;
                    return { ...prev, liczbaPokoiCheckbox: liczbaPokoi.checked };
                    break;
                case 'pietro':
                    return { ...prev, pietro: input.value };
                    break;
                case 'pietroCheckbox':
                    const pietro = input as HTMLInputElement;
                    return { ...prev, pietroCheckbox: pietro.checked };
                    break;
                case 'powierzchnia':
                    const computedForPowierzchnia = Math.floor(+input.value * +prev.cenaZaMetrKwadratowy);
                    return { ...prev, powierzchnia: input.value, cena: computedForPowierzchnia.toString() };
                    break;
                case 'cena_za_metr_kwadratowy':
                    const computedForCenaZaMetrKwadratowy = Math.floor(+prev.powierzchnia * +input.value);
                    return { ...prev, cenaZaMetrKwadratowy: input.value, cena: computedForCenaZaMetrKwadratowy.toString() };
                    break;
                case 'cena':
                    const computedForCena = +input.value / +prev.powierzchnia
                    return { ...prev, cena: input.value, cenaZaMetrKwadratowy: (Math.floor(computedForCena * 100) / 100).toString() };
                    break;
                case 'rodzaj_budynku':
                    return { ...prev, rodzajBudynku: input.value };
                    break;
                case 'rodzajBudynkuCheckbox':
                    const rodzajBudynku = input as HTMLInputElement;
                    return { ...prev, rodzajBudynkuCheckbox: rodzajBudynku.checked };
                    break;
                case 'standard':
                    return { ...prev, standard: input.value };
                    break;
                case 'standardCheckbox':
                    const standard = input as HTMLInputElement;
                    return { ...prev, standardCheckbox: standard.checked };
                    break;
                case 'stan_lokalu':
                    return { ...prev, stanLokalu: input.value };
                    break;
                case 'stanLokaluCheckbox':
                    const stanLokalu = input as HTMLInputElement;
                    return { ...prev, stanLokaluCheckbox: stanLokalu.checked };
                    break;
                case 'okna':
                    return { ...prev, okna: input.value };
                    break;
                case 'oknaCheckbox':
                    const okna = input as HTMLInputElement;
                    return { ...prev, oknaCheckbox: okna.checked };
                    break;
                case 'balkonCheckbox':
                    const balkon = input as HTMLInputElement;
                    return { ...prev, balkonCheckbox: balkon.checked };
                    break;
                case 'opis':
                    return { ...prev, opis: input.value };
                    break;
                default:
                    return prev;
                    break;
            }
        });
    }

    function deleteCustomProperty(index: number): void {
        setCustomProperties(prev => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    }

    function addCustomProperty(): void {
        setCustomProperties(prev => [...prev, { key: '', value: '' }]);
    }

    function changeCustomPropertyKey(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        const input = e.target as HTMLInputElement;
        const newKey = input.value;
        setCustomProperties((prev) => {
            const newArray = prev.map((item, indexOld) => {
                return indexOld === index ? { ...item, key: newKey } : item
            });
            return newArray;
        })
    }

    function changeCustomPropertyValue(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        const input = e.target as HTMLInputElement;
        const newValue = input.value;
        setCustomProperties((prev) => {
            const newArray = prev.map((item, indexOld) => {
                return indexOld === index ? { ...item, value: newValue } : item
            });
            return newArray;
        })
    }

    function addImageSlot(): void {
        setImages(prev => [...prev, '/image-placeholder.png']);
    }

    function changeImagePreview(e: React.ChangeEvent<HTMLInputElement>, index: number) {
        const input = e.target as HTMLInputElement;
        const file = input.files && input.files[0];
        if (file && file.type.includes('image/')) {
            const url = URL.createObjectURL(file);
            setImages((prev) => {
                const newArray = prev.map((item, indexOld) => {
                    return indexOld === index ? url : item
                });
                return newArray;
            })
        }
        else {
            alert('Wybierz plik, który jest zdjęciem');
            return;
        }
    }

    function deleteImage(index: number) {
        setImages(prev => [...prev.slice(0, index), ...prev.slice(index + 1)]);
    }

    function submitOffer(e: FormEvent<HTMLFormElement>): void {
        e.preventDefault();
        setIsUploading(true);
        const form = e.target as HTMLFormElement;
        const fileContainers = form.querySelectorAll('[data-id="image"]');
        let files: Blob[] = [];
        const data = new FormData();
        fileContainers.forEach((container, index) => {
            const input = container.querySelector('input') as HTMLInputElement;
            const imageLabel = container.querySelector('[data-id="imageLabel"]') as HTMLImageElement;
            const source = imageLabel.src;
            data.append(`paths[${index}]`, source);
            const file = input.files![0];
            files.push(file);
        })
        files.forEach((file, index) => {
            data.append(`zdjecia[${index}]`, file);
        });
        const balkon = form.querySelector('[name="balkon"]:checked') as HTMLInputElement;
        const agent = form.querySelector('[name="agent"]:checked') as HTMLInputElement;

        if (!isOfferActive) {
            const client = form.querySelector('[name="klient"]:checked') as HTMLInputElement;
            const imie = form.querySelector('#imie') as HTMLInputElement;
            const nazwisko = form.querySelector('#nazwisko') as HTMLInputElement;
            const firma = form.querySelector('#firma') as HTMLInputElement;
            const nr_telefonu = form.querySelector('#nr_telefonu') as HTMLInputElement;
            const email = form.querySelector('#email') as HTMLInputElement;
            if (imie.value !== '') {
                const klient_new = {
                    imie: imie.value,
                    nazwisko: nazwisko.value,
                    nazwa_firmy: firma.value,
                    nr_telefonu: nr_telefonu.value,
                    email: email.value
                };
                data.append('klient_new', JSON.stringify(klient_new));
                data.append('klient_existing', 'null');
            }
            else {
                if (client) {
                    data.append('klient_existing', client.id.substring('klient'.length));
                }
                else {
                    setValidationError('Wybierz klienta');
                    setIsUploading(false);
                    return;
                }
            }
        }
        else {
            data.append('klient_existing', 'null');
        }

        data.append('nazwa', ofertaFormData.nazwa);
        data.append('miasto', ofertaFormData.miasto);
        data.append('dzielnica', ofertaFormData.dzielnicaCheckbox ? 'null' : ofertaFormData.dzielnica);
        data.append('numer_oferty', ofertaFormData.numerOferty);
        data.append('typ_nieruchomosci', ofertaFormData.typNieruchomosci);
        data.append('rodzaj', ofertaFormData.rodzaj.charAt(0).toLowerCase() + ofertaFormData.rodzaj.slice(1));
        data.append('rynek', ofertaFormData.rynek.charAt(0).toLowerCase() + ofertaFormData.rynek.slice(1));
        data.append('liczba_pokoi', ofertaFormData.liczbaPokoiCheckbox ? 'null' : ofertaFormData.liczbaPokoi);
        data.append('pietro', ofertaFormData.pietroCheckbox ? 'null' : ofertaFormData.pietro);
        data.append('powierzchnia', ofertaFormData.powierzchnia);
        data.append('cena_za_metr_kwadratowy', ofertaFormData.cenaZaMetrKwadratowy);
        data.append('cena', ofertaFormData.cena);
        data.append('rodzaj_budynku', ofertaFormData.rodzajBudynkuCheckbox ? 'null' : ofertaFormData.rodzajBudynku);
        data.append('standard', ofertaFormData.standardCheckbox ? 'null' : ofertaFormData.standard);
        data.append('stan_lokalu', ofertaFormData.stanLokaluCheckbox ? 'null' : ofertaFormData.stanLokalu);
        data.append('okna', ofertaFormData.oknaCheckbox ? 'null' : ofertaFormData.okna);
        let balkonValue: string = '';
        if (balkon) {
            if (balkon.id === 'balkonTak') {
                balkonValue = '1';
            }
            else if (balkon.id === 'balkonNie') {
                balkonValue = '0';
            }
        }
        data.append('balkon', ofertaFormData.balkonCheckbox ? 'null' : balkonValue);
        data.append('opis', ofertaFormData.opis);
        if (agent) {
            data.append('agent', agent.id.substring('agent'.length));
        }
        else {
            setValidationError('Wybierz agenta');
            setIsUploading(false);
            return;
        }
        data.append('aktywna', isOfferActive ? '1' : '0');
        if (!id) {
            data.append('featured', featured ? '1' : '0');
        }
        data.append('custom_properties', JSON.stringify(customProperties));

        if (id) {
            data.append('_method', 'put');
            axiosClient({
                method: 'post',
                url: `/offers/${id}`,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data
            })
                .then(res => {
                    setValidationError(null);
                    setInvalidContainer(null);
                    setNotification(res.data.message);
                    setTimeout(() => setNotification(null), 4000);
                })
                .catch(err => {
                    if (err?.response?.status === 422) {
                        const message = err.response.data.message;
                        if (message !== 'Powierzchnia musi być liczbą' && message !== 'Cena za metr kwadratowy musi być liczbą' && message !== 'Cena musi być liczbą' && message !== 'Zdjęcia muszą być tablicą' && message !== 'Maksymalna ilość zdjęć to 50' && message !== 'Plik musi być zdjęciem' && message !== 'Plik może ważyć maksymalnie 2 MB' && message !== 'Zły format niestandardowych szczegółów' && message !== 'Podaj poprawne id agenta' && message !== 'Wypełnij wszystkie wymagane dane klienta' && message !== 'Numer oferty musi być unikatowy, a istnieje już oferta o tym numerze') {
                            setValidationError(`Pole ${message} jest wymagane`);
                            setInvalidContainer(message);
                        }
                        else {
                            setValidationError(message);
                        }
                    }
                    else {
                        setError('Coś poszło nie tak, spróbuj ponownie później...')
                    }
                })
                .finally(() => setIsUploading(false));
        }
        else {
            axiosClient({
                method: 'post',
                url: '/offers',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                data
            })
                .then(res => {
                    setValidationError(null);
                    setInvalidContainer(null);
                    setNotification(res.data.message);
                    setTimeout(() => setNotification(null), 4000);
                })
                .catch(err => {
                    console.log(err);
                    if (err?.response?.status === 422) {
                        const message = err.response.data.message;
                        if (message !== 'Pole wyróżniona jest wymagane' && message !== 'Powierzchnia musi być liczbą' && message !== 'Cena za metr kwadratowy musi być liczbą' && message !== 'Cena musi być liczbą' && message !== 'Zdjęcia muszą być tablicą' && message !== 'Maksymalna ilość zdjęć to 50' && message !== 'Plik musi być zdjęciem' && message !== 'Plik może ważyć maksymalnie 2 MB' && message !== 'Zdjęcia są wymagane' && message !== 'Zły format niestandardowych szczegółów' && message !== 'Podaj poprawne id agenta' && message !== 'Wypełnij wszystkie wymagane dane klienta' && message !== 'Numer oferty musi być unikatowy, a istnieje już oferta o tym numerze') {
                            setValidationError(`Pole ${message} jest wymagane`);
                            setInvalidContainer(message);
                        }
                        else {
                            setValidationError(message);
                        }
                    }
                    else {
                        setError('Coś poszło nie tak, spróbuj ponownie później...')
                    }
                })
                .finally(() => setIsUploading(false));
        }
    }

    function toggleFeatured(e: React.ChangeEvent<HTMLInputElement>): void {
        const checkbox = e.target as HTMLInputElement;
        setFeatured(checkbox.checked);
        if (id) {
            axiosClient({
                method: 'put',
                url: `/toggle-featured/${id}`
            })
                .then(res => {
                    if (res.status === 200) {
                        setNotification('Usunięto z wyróżnionych');
                    }
                    else if (res.status === 201) {
                        setNotification('Dodano do wyróżnionych');
                    }
                    setTimeout(() => setNotification(null), 4000);
                })
                .catch(err => setError('Coś poszło nie tak, spróbuj ponownie później...'));
        }
    }

    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <main className={styles.main}>
            <Notification>{notification}</Notification>
            <form onSubmit={submitOffer} encType='multipart/form-data' className={styles.form}>
                <div className={`${styles.form__container} ${invalidContainer === 'nazwa' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="nazwa">Nazwa</label>
                    <input value={ofertaFormData.nazwa} onChange={handleInputChange} className={styles.form__input} id='nazwa' type="text" />
                </div>
                <div className={`${styles.form__container} ${invalidContainer === 'miasto' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="miasto">Miasto</label>
                    <input value={ofertaFormData.miasto} onChange={handleInputChange} className={styles.form__input} id='miasto' type="text" />
                </div>
                <div className={`${styles.form__row_end} ${invalidContainer === 'dzielnica' && styles.form__container_invalid}`}>
                    <div className={styles.form__container}>
                        <label className={styles.form__label} htmlFor="dzielnica">Dzielnica</label>
                        <input value={ofertaFormData.dzielnica} onChange={handleInputChange} className={styles.form__input} id='dzielnica' type="text" />
                    </div>
                    <div className={styles.form__row}>
                        <input checked={ofertaFormData.dzielnicaCheckbox} onChange={handleInputChange} type="checkbox" id="dzielnicaCheckbox" />
                        <label className={styles.form__label} htmlFor="dzielnicaCheckbox">Nie dotyczy</label>
                    </div>
                </div>
                <div className={`${styles.form__container} ${invalidContainer === 'numer oferty' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="numer_oferty">Numer oferty</label>
                    <input value={ofertaFormData.numerOferty} onChange={handleInputChange} className={styles.form__input} id='numer_oferty' type="text" />
                </div>
                <div className={`${styles.form__container} ${invalidContainer === 'typ nieruchomości' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="typ_nieruchomosci">Typ nieruchomości</label>
                    <select value={ofertaFormData.typNieruchomosci} onChange={handleInputChange} className={styles.form__select} id="typ_nieruchomosci">
                        <option value="Mieszkanie">Mieszkanie</option>
                        <option value="Dom">Dom</option>
                        <option value="Lokal">Lokal</option>
                        <option value="Działka">Działka</option>
                    </select>
                </div>
                <div className={`${styles.form__container} ${invalidContainer === 'rodzaj' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="rodzaj">Rodzaj</label>
                    <select value={ofertaFormData.rodzaj} onChange={handleInputChange} className={styles.form__select} id="rodzaj">
                        <option value="Sprzedaż">Sprzedaż</option>
                        <option value="Wynajem">Wynajem</option>
                    </select>
                </div>
                <div className={`${styles.form__container} ${invalidContainer === 'rynek' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="rynek">Rynek</label>
                    <select value={ofertaFormData.rynek} onChange={handleInputChange} className={styles.form__select} id="rynek">
                        <option value="Wtórny">Wtórny</option>
                        <option value="Pierwotny">Pierwotny</option>
                    </select>
                </div>
                <div className={`${styles.form__row_end} ${invalidContainer === 'liczba pokoi' && styles.form__container_invalid}`}>
                    <div className={styles.form__container}>
                        <label className={styles.form__label} htmlFor="liczba_pokoi">Liczba pokoi</label>
                        <input value={ofertaFormData.liczbaPokoi} onChange={handleInputChange} className={styles.form__input} id='liczba_pokoi' type="number" min={0} />
                    </div>
                    <div className={styles.form__row}>
                        <input checked={ofertaFormData.liczbaPokoiCheckbox} onChange={handleInputChange} type="checkbox" id="liczbaPokoiCheckbox" />
                        <label className={styles.form__label} htmlFor="liczbaPokoiCheckbox">Nie dotyczy</label>
                    </div>
                </div>
                <div className={`${styles.form__row_end} ${invalidContainer === 'piętro' && styles.form__container_invalid}`}>
                    <div className={styles.form__container}>
                        <label className={styles.form__label} htmlFor="pietro">Piętro</label>
                        <input value={ofertaFormData.pietro} onChange={handleInputChange} className={styles.form__input} id='pietro' type="number" min={0} />
                    </div>
                    <div className={styles.form__row}>
                        <input checked={ofertaFormData.pietroCheckbox} onChange={handleInputChange} type="checkbox" id="pietroCheckbox" />
                        <label className={styles.form__label} htmlFor="pietroCheckbox">Nie dotyczy</label>
                    </div>
                </div>
                <div className={`${styles.form__container} ${invalidContainer === 'powierzchnia' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="powierzchnia">Powierzchnia</label>
                    <input value={ofertaFormData.powierzchnia} onChange={handleInputChange} className={styles.form__input} id='powierzchnia' type="number" min={0} step={0.01} />
                </div>
                <div className={`${styles.form__container} ${invalidContainer === 'cena za metr kwadratowy' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="cena_za_metr_kwadratowy">Cena za metr kwadratowy</label>
                    <input value={ofertaFormData.cenaZaMetrKwadratowy} onChange={handleInputChange} className={styles.form__input} id='cena_za_metr_kwadratowy' type="number" min={0} step={0.01} />
                </div>
                <div className={`${styles.form__container} ${invalidContainer === 'cena' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="cena">Cena</label>
                    <input onChange={handleInputChange} value={ofertaFormData.cena} className={styles.form__input} id='cena' type="number" min={0} step={0.01} />
                </div>
                <div className={`${styles.form__row_end} ${invalidContainer === 'rodzaj budynku' && styles.form__container_invalid}`}>
                    <div className={styles.form__container}>
                        <label className={styles.form__label} htmlFor="rodzaj_budynku">Rodzaj budynku</label>
                        <input value={ofertaFormData.rodzajBudynku} onChange={handleInputChange} className={styles.form__input} id='rodzaj_budynku' type="text" />
                    </div>
                    <div className={styles.form__row}>
                        <input checked={ofertaFormData.rodzajBudynkuCheckbox} onChange={handleInputChange} type="checkbox" id="rodzajBudynkuCheckbox" />
                        <label className={styles.form__label} htmlFor="rodzajBudynkuCheckbox">Nie dotyczy</label>
                    </div>
                </div>
                <div className={`${styles.form__row_end} ${invalidContainer === 'standard' && styles.form__container_invalid}`}>
                    <div className={styles.form__container}>
                        <label className={styles.form__label} htmlFor="standard">Standard</label>
                        <input value={ofertaFormData.standard} onChange={handleInputChange} className={styles.form__input} id='standard' type="number" min={0} max={5} />
                    </div>
                    <div className={styles.form__row}>
                        <input checked={ofertaFormData.standardCheckbox} onChange={handleInputChange} type="checkbox" id="standardCheckbox" />
                        <label className={styles.form__label} htmlFor="standardCheckbox">Nie dotyczy</label>
                    </div>
                </div>
                <div className={`${styles.form__row_end} ${invalidContainer === 'stan lokalu' && styles.form__container_invalid}`}>
                    <div className={styles.form__container}>
                        <label className={styles.form__label} htmlFor="stan_lokalu">Stan lokalu</label>
                        <input value={ofertaFormData.stanLokalu} onChange={handleInputChange} className={styles.form__input} id='stan_lokalu' type="text" />
                    </div>
                    <div className={styles.form__row}>
                        <input checked={ofertaFormData.stanLokaluCheckbox} onChange={handleInputChange} type="checkbox" id="stanLokaluCheckbox" />
                        <label className={styles.form__label} htmlFor="stanLokaluCheckbox">Nie dotyczy</label>
                    </div>
                </div>
                <div className={`${styles.form__row_end} ${invalidContainer === 'okna' && styles.form__container_invalid}`}>
                    <div className={styles.form__container}>
                        <label className={styles.form__label} htmlFor="okna">Okna</label>
                        <input value={ofertaFormData.okna} onChange={handleInputChange} className={styles.form__input} id='okna' type="text" />
                    </div>
                    <div className={styles.form__row}>
                        <input checked={ofertaFormData.oknaCheckbox} onChange={handleInputChange} type="checkbox" id="oknaCheckbox" />
                        <label className={styles.form__label} htmlFor="oknaCheckbox">Nie dotyczy</label>
                    </div>
                </div>
                <p className={styles.sectionHeading}>Niestandardowe szczegóły</p>
                {
                    customProperties.map((property, index) => {
                        return (
                            <div key={index} data-id="customProperty" className={styles.form__row}>
                                <div className={styles.form__container}>
                                    <label className={styles.form__label} htmlFor={`nazwa${index}`}>Nazwa</label>
                                    <input onChange={(e) => changeCustomPropertyKey(e, index)} value={property.key} className={styles.form__input} id={`nazwa${index}`} type="text" placeholder='Liczba pięter w budynku' />
                                </div>
                                <div className={styles.form__container}>
                                    <label className={styles.form__label} htmlFor={`wartosc${index}`}>Wartość</label>
                                    <input onChange={(e) => changeCustomPropertyValue(e, index)} value={property.value} className={styles.form__input} id={`wartosc${index}`} type="text" placeholder='4' />
                                </div>
                                {index !== 0 &&
                                    <button onClick={() => deleteCustomProperty(index)} type='button' className={styles.form__delete}>
                                        <AiOutlineDelete />
                                    </button>}
                            </div>
                        )
                    })
                }
                <button onClick={addCustomProperty} type='button' className={styles.form__add}>
                    <MdAddBox />
                </button>
                <div className={`${styles.form__container} ${invalidContainer === 'balkon' && styles.form__container_invalid}`}>
                    <p className={styles.form__label}>Balkon</p>
                    <div className={styles.form__row}>
                        <div className={styles.form__row}>
                            <input type="radio" name='balkon' id="balkonTak" />
                            <label className={styles.form__label} htmlFor="balkonTak">Tak</label>
                        </div>
                        <div className={styles.form__row}>
                            <input type="radio" name='balkon' id="balkonNie" />
                            <label className={styles.form__label} htmlFor="balkonNie">Nie</label>
                        </div>
                        <div className={styles.form__row}>
                            <input checked={ofertaFormData.balkonCheckbox} onChange={handleInputChange} type="checkbox" id="balkonCheckbox" />
                            <label className={styles.form__label} htmlFor="balkonCheckbox">Nie dotyczy</label>
                        </div>
                    </div>
                </div>
                <div className={`${styles.form__container} ${invalidContainer === 'opis' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="opis">Opis</label>
                    <textarea value={ofertaFormData.opis} onChange={handleInputChange} className={styles.form__textarea} id="opis" cols={50} rows={20}></textarea>
                </div>
                <div className={`${styles.form__container} ${invalidContainer === 'agent' && styles.form__container_invalid}`}>
                    <p className={styles.sectionHeading}>Agent</p>
                    {
                        agents?.map((agent, index) => {
                            return (
                                <div key={index} className={styles.form__row}>
                                    <input type="radio" name="agent" id={`agent${agent.id}`} />
                                    <label className={styles.form__label} htmlFor={`agent${agent.id}`}>{agent.imie} {agent.nazwisko}</label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={`${styles.form__row} ${invalidContainer === 'aktywna' && styles.form__container_invalid}`}>
                    <label className={styles.form__label} htmlFor="aktywna">Oferta widoczna na stronie (nie jest zajęta)</label>
                    <input onChange={() => setIsOfferActive(prev => !prev)} checked={isOfferActive} aria-checked={isOfferActive} type="checkbox" id="aktywna" />
                </div>
                {
                    !isOfferActive &&
                    <div className={`${styles.form__container} ${invalidContainer === 'klient' && styles.form__container_invalid}`}>
                        <p className={styles.sectionHeading}>Nabywca</p>
                        {
                            (clients && clients.length > 0) &&
                            <>
                                {
                                    clients.map((client, index) => {
                                        return (
                                            <div key={index} className={styles.form__row}>
                                                <input type="radio" name="klient" id={`klient${client.id}`} />
                                                <label className={styles.form__label} htmlFor={`klient${client.id}`}>{client.imie} {client.nazwisko}</label>
                                            </div>
                                        )
                                    })
                                }
                                <p className={styles.sectionHeading}>lub inny</p>
                            </>
                        }
                        <div className={styles.form__container}>
                            <label className={styles.form__label} htmlFor="imie">Imię</label>
                            <input className={styles.form__input} id='imie' type="text" />
                        </div>
                        <div className={styles.form__container}>
                            <label className={styles.form__label} htmlFor="nazwisko">Nazwisko</label>
                            <input className={styles.form__input} id='nazwisko' type="text" />
                        </div>
                        <div className={styles.form__container}>
                            <label className={styles.form__label} htmlFor="firma">Nazwa firmy(opcjonalne)</label>
                            <input className={styles.form__input} id='firma' type="text" />
                        </div>
                        <div className={styles.form__container}>
                            <label className={styles.form__label} htmlFor="nr_telefonu">Nr telefonu</label>
                            <input className={styles.form__input} id='nr_telefonu' type="text" />
                        </div>
                        <div className={styles.form__container}>
                            <label className={styles.form__label} htmlFor="email">Email</label>
                            <input className={styles.form__input} id='email' type="email" />
                        </div>
                    </div>
                }
                <div className={styles.form__container}>
                    <p className={styles.sectionHeading}>Zdjęcia(pierwsze jest automatycznie miniaturą)</p>
                    {
                        images.map((item, index) => {
                            return (
                                <div key={index} data-id='image' className={styles.imageContainer}>
                                    <label htmlFor={`image${index}`}>
                                        <img style={{ objectFit: item === '/image-placeholder.png' ? 'contain' : 'cover' }} data-id="imageLabel" className={styles.form__imgLabel} src={item} alt="obraz" />
                                    </label>
                                    {
                                        index !== 0 &&
                                        <button onClick={() => deleteImage(index)} type='button' className={styles.form__delete}>
                                            <AiOutlineDelete />
                                        </button>
                                    }
                                    <input onChange={(e) => changeImagePreview(e, index)} id={`image${index}`} type="file" style={{ display: 'none' }} />
                                </div>
                            )
                        })
                    }
                    <button onClick={addImageSlot} type='button' className={styles.form__add}>
                        <MdAddBox />
                    </button>
                </div>
                {
                    isUploading &&
                    <p className={styles.form__loading}>Trwa przesyłanie <img className={styles.form__loading__animation} src="/loading.gif" alt="ładowanie" /></p>
                }
                <button className={styles.form__button}>Zapisz</button>
                {
                    validationError &&
                    <p className={styles.form__error}>{validationError}</p>
                }
            </form>
            <form>
                <label className={`${styles.sectionHeading} ${styles.sectionHeading_last}`} htmlFor='featured'>Ustaw jako wyróżniona oferta w homepage{id && '(wystarczy zaznaczyć)'}</label>
                <input checked={featured} aria-checked={featured} onChange={toggleFeatured} type="checkbox" id='featured' />
            </form>
        </main>
    )
}

export default OfertaForm
