import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AgentTile from '../../components/agentTile/AgentTile';
import axiosClient from '../../axiosClient';
import axios from 'axios';


import styles from './agenci.module.css';

interface Agent {
    id: number;
    imie: string;
    nazwisko: string;
    zdjecie: string;
    zawod: string;
    nr_telefonu: string;
    email: string;
    created_at: string;
    updated_at: string;
}

const Agenci = () => {
    const [agents, setAgents] = useState<Agent[] | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const source = axios.CancelToken.source();

        axiosClient({
            method: 'get',
            url: '/agents',
            cancelToken: source.token
        })
            .then(res => {
                setError(null);
                setAgents(res.data);
            })
            .catch(err => {
                setAgents(null);
                setError('Coś poszło nie tak, spróbuj ponownie później...');
            })

        return () => {
            source.cancel();
        }

    }, []);

    if (error) {
        return <p className='error' role='alert' aria-live='assertive'>{error}</p>
    }

    return (
        <main className={styles.main}>
            <Link to='/agenci/new' className={styles.button}>Dodaj agenta</Link>
            {
                !agents ? <img className='loading' src="/loading.gif" alt="ładowanie" /> : agents.map(agent => <AgentTile
                    key={agent.id}
                    id={agent.id}
                    zdjecie={agent.zdjecie}
                    imieINazwisko={`${agent.imie} ${agent.nazwisko}`}
                    zawod={agent.zawod}
                />)
            }
        </main>
    )
}

export default Agenci
